// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-agentur-js": () => import("./../src/pages/agentur.js" /* webpackChunkName: "component---src-pages-agentur-js" */),
  "component---src-pages-aufwandsschaetzung-js": () => import("./../src/pages/aufwandsschaetzung.js" /* webpackChunkName: "component---src-pages-aufwandsschaetzung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-playground-js": () => import("./../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */)
}

