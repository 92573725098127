import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useRef, useEffect } from "react"
import AOS from "aos"
/* import "aos/dist/aos.css" */
import gsap, { TimelineMax } from "gsap"
import $ from "jquery"
import Logo from "../svg/logo-text.svg"

import TransitionLink from "gatsby-plugin-transition-link"

import { CSSPlugin } from "gsap/CSSPlugin"

import styled from "styled-components"

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)
// Listen to a test, give it a callback
//var modernizr = require("modernizr");

const Header = ({ siteTitle }) => {
  let burgerMenu = useRef(null)
  let mainMenu = useRef(null)

  var menu = {
    open: false,
    animatePage: false,
  }

  function toggleMenuAnimations() {
    burgerMenu.classList.toggle("-open")
    mainMenu.classList.toggle("-open")
  }

  const onclick = () => {}

  useEffect(() => {
    console.log("\x1b[43mMadeWithLoveByCuuno")

    if (typeof window !== "undefined" && window.innerWidth >= 768) {
      AOS.init({
        offset: 100,
        duration: 1600,
        easing: "outexpo",
        delay: 150,
      })
      if (AOS) {
        AOS.refresh()
      }
    }
    $(".cb-nav-haslist").mouseover(() => {
      $(".cb-nav-links-box").css("display", "flex")
    })
    $(".cb-nav-haslist").on("mouseleave", () => {
      $(".cb-nav-links-box").css("display", "none")
    })
  }, [])

  const transitionLength = 0.25

  function interestingEntryAnimation(exit, node) {
    window.scrollTo(0, 0)
    let tl = new TimelineMax()
    tl.from(node, transitionLength, { opacity: 0 })

    /*  $(node).fadeIn(transitionLength) */
  }
  function interestingExitAnimation(exit, node) {
    let tl = new TimelineMax()
    tl.to(node, transitionLength, { opacity: 0 })
    /*   $(node).fadeOut(transitionLength) */
  }

  return (
    <React.Fragment>
      <header className="cb-nav">
        <nav className="cb-container  -flex -justify-content-sb -middle">
          <div className="cb-nav-grid">
            <div className="cb-nav-grid-col -left">
              <TransitionLink
                to="/"
                className="cb-logo"
                aria-label="home"
                exit={{
                  trigger: ({ exit, node }) =>
                    interestingExitAnimation(exit, node),
                  length: transitionLength,
                }}
                entry={{
                  trigger: ({ entry, node }) =>
                    interestingEntryAnimation(entry, node),
                  length: transitionLength,
                  delay: transitionLength,
                }}
              >
                <Logo />
              </TransitionLink>
            </div>
            <div className="cb-nav-grid-col -right">
              <div className="cb-nav-mobile-menu">
                <ul
                  onClick={() => {
                    if (window.innerWidth < 960) {
                      $(".cb-nav-burger").toggleClass("-open")
                      $(".cb-nav-mobile-menu").toggleClass("-open")
                      $(".cb-logo").toggleClass("-open")
                    }
                  }}
                  className="cb-nav-links"
                >
                  <li>
                    <TransitionLink
                      to="/leistungen/"
                      activeClassName="active"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                    >
                      Leistungen
                    </TransitionLink>
                  </li>

                  <li>
                    <TransitionLink
                      to="/agentur"
                      activeClassName="active"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                      activeClassName="active"
                    >
                      Agentur
                    </TransitionLink>
                  </li>

                  <li>
                    <TransitionLink
                      to="/blog"
                      activeClassName="active"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                    >
                      Blog
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink
                      to="/playground"
                      activeClassName="active"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                    >
                      Playground
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink
                      to="/aufwandsschaetzung/"
                      activeClassName="active"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                    >
                      Aufwandsschätzung
                    </TransitionLink>
                  </li>
                  <li>
                    <TransitionLink
                      to="/kontakt"
                      className="-menu-item-blue"
                      exit={{
                        trigger: ({ exit, node }) =>
                          interestingExitAnimation(exit, node),
                        length: transitionLength,
                      }}
                      entry={{
                        trigger: ({ entry, node }) =>
                          interestingEntryAnimation(entry, node),
                        length: transitionLength,
                        delay: transitionLength,
                      }}
                    >
                      <span> Kontakt</span>
                    </TransitionLink>
                  </li>
                </ul>

                <ul className="cb-nav-socials">
                  <li>
                    <a
                      aria-label="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/cuunodotcom/"
                    >
                      <svg viewBox="0 0 169.063 169.063">
                        <path
                          d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
 c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
 c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
 c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                        ></path>
                        <path
                          d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
 C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
 c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                        ></path>
                        <path
                          d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
 c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
 C135.661,29.421,132.821,28.251,129.921,28.251z"
                        ></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Dribbble"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://dribbble.com/cuunodotcom"
                    >
                      <svg height="512" viewBox="0 0 24 24" width="512">
                        <path d="m16.354 23.17c.02-.006.039-.014.059-.021 12.215-4.853 8.836-23.149-4.413-23.149-6.649 0-12 5.42-12 12 0 8.327 8.434 14.269 16.354 11.17zm-11.073-3.111c.852-1.432 3.609-5.472 8.315-6.866.984 2.509 1.674 5.436 1.742 8.755-3.566 1.199-7.327.392-10.057-1.889zm11.532 1.263c-.131-3.189-.782-6.017-1.71-8.467 2.082-.325 4.492-.108 7.225.987-.581 3.261-2.666 6.002-5.515 7.48zm5.672-9.031c-3.01-1.131-5.663-1.272-7.959-.834-.355-.8-.728-1.569-1.123-2.277 3.523-1.407 5.605-3.122 6.537-4.03 1.645 1.904 2.622 4.369 2.545 7.141zm-3.61-8.209c-.848.807-2.845 2.437-6.26 3.77-1.674-2.648-3.464-4.516-4.598-5.562 3.628-1.494 7.812-.856 10.858 1.792zm-12.292-1.059c.856.753 2.735 2.561 4.548 5.357-2.49.802-5.612 1.391-9.409 1.474.604-2.894 2.408-5.346 4.861-6.831zm-5.051 8.337c4.25-.069 7.69-.74 10.409-1.648.376.659.733 1.377 1.076 2.123-4.735 1.508-7.694 5.401-8.827 7.159-1.828-2.04-2.836-4.702-2.658-7.634z"></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/cuuno-webagentur/"
                    >
                      <svg height="512" viewBox="0 0 24 24" width="512">
                        <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                      href=""
                    >
                      <svg
                        height="512"
                        viewBox="0 0 24 24"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"></path>
                        <path d="m.396 7.977h4.976v16.023h-4.976z"></path>
                        <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                onClick={event => {
                  $(event.currentTarget).toggleClass("-open")
                  $(".cb-nav-mobile-menu").toggleClass("-open")
                  $(".cb-logo").toggleClass("-open")
                }}
                className="cb-nav-burger"
              >
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </nav>

     {/*    <div ref={el => (mainMenu = el)} className="cb-menu">
          <div ref={el => (menuFill = el)} className="cb-menu-fill"></div>
          <div className="cb-menu-body">
            <div className="cb-menu-body-bg"></div>
            <div ref={el => (menuBody = el)} className="cb-menu-body-content">
              <div className="cb-container">
                <div className="cb-menu-body-content-wrap">
                  <div className="cb-nav-info"></div>
                </div>
              </div>
            </div>
          </div>
          <div ref={el => (menuBG = el)} className="cb-menu-overlay"></div>
        </div> */}
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
